<template>
  <v-dialog v-model="dialog" width="500">
    <v-card class="pa-2">
      <v-card-title class="my-2 justify-center" v-text="title"></v-card-title>
      <v-card-subtitle v-if="subtitle" class="text-center" v-text="subtitle"></v-card-subtitle>

      <v-card-actions>
        <vx-btn class="mx-auto" width="100" color="primary" @click="onClick()">Ok</vx-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertDialog',

  data() {
    return {
      dialog: false,
      title: null,
      subtitle: null,
      callback: null,
    };
  },
  mounted() {
    this.$root.$on('alert', ({ title, subtitle, callback }) => {
      this.dialog = true;
      this.title = title;
      this.subtitle = subtitle;
      this.callback = callback;
    });
  },
  methods: {
    onClick() {
      this.dialog = false;
      if (typeof callback === 'function') {
        this.callback();
      }
    },
  },
};
</script>
