var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Filters', {
    attrs: {
      "options": _vm.options
    },
    on: {
      "getData": function getData($event) {
        return _vm.getData();
      },
      "resetAndGetData": function resetAndGetData($event) {
        return _vm.resetAndGetData();
      }
    }
  }, [_vm._t("filter")], 2), _c('v-card', [_c('v-card-title', {
    staticClass: "d-flex"
  }, [_c('v-row', [_vm.options.export || _vm.options.addNew || _vm.actionsLength || _vm.options.sync ? _c('v-col', {
    staticClass: "text-left text-sm-right",
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "8",
      "xl": "9"
    }
  }, [_c('v-row', [_vm.actionsLength ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('vx-menu', {
    attrs: {
      "btn-props": {
        block: true,
        outlined: true,
        disabled: !_vm.dataTable.value.length
      },
      "label": "Actions",
      "items": _vm.options.actions
    },
    on: {
      "click": _vm.onChangeAction
    },
    model: {
      value: _vm.action,
      callback: function callback($$v) {
        _vm.action = $$v;
      },
      expression: "action"
    }
  })], 1) : _vm._e(), _vm.options.export ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary",
      "disabled": !_vm.dataTable.itemsLength
    },
    on: {
      "click": function click($event) {
        return _vm.exportAll();
      }
    }
  }, [_vm._v(" Export ")])], 1) : _vm._e(), _vm._t("addOptions"), _vm.options.addNew ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addNew();
      }
    }
  }, [_vm._v("Add new")])], 1) : _vm._e(), _vm.options.sync && _vm.dev ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.sync();
      }
    }
  }, [_vm._v("Sync ")])], 1) : _vm._e()], 2)], 1) : _vm._e(), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "4",
      "xl": "3"
    }
  }, [_c('vx-input', {
    class: {
      'v-input-border': _vm.options.search.text
    },
    attrs: {
      "type": "text",
      "hide-details": "",
      "label": "Search"
    },
    on: {
      "input": function input($event) {
        return _vm.debounce();
      }
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.resetAndGetData();
      }
    },
    model: {
      value: _vm.options.search.text,
      callback: function callback($$v) {
        _vm.$set(_vm.options.search, "text", $$v);
      },
      expression: "options.search.text"
    }
  })], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "show-select": _vm.actionsLength,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "options": _vm.dataTable.options,
      "server-items-length": _vm.dataTable.itemsLength,
      "item-key": _vm.dataTable.key,
      "footer-props": _vm.dataTable.footerProps,
      "expanded": _vm.expanded
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      },
      "update:expanded": function updateExpanded($event) {
        _vm.expanded = $event;
      },
      "update:page": function updatePage($event) {
        return _vm.getData();
      },
      "update:items-per-page": function updateItemsPerPage($event) {
        return _vm.getData();
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.getData();
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "body.prepend",
      fn: function fn(_ref) {
        var headers = _ref.headers;
        return [_c('tr', _vm._l(headers, function (header, index) {
          return _c('td', {
            key: index
          }, [header.filter && header.filter.type ? [_c('vx-input', {
            class: {
              'v-input-border': _vm.options.filter[header.value] && _vm.options.filter[header.value].length
            },
            attrs: {
              "type": header.filter.type,
              "multiple": "",
              "hide-details": "",
              "disabled": header.filter.disabled,
              "label": header.filter.label,
              "items": header.filter.items,
              "item-text": header.filter.itemText,
              "item-value": header.filter.itemValue
            },
            on: {
              "change": function change($event) {
                return _vm.resetAndGetData();
              },
              "click:clear": function clickClear($event) {
                return _vm.resetAndGetData();
              }
            },
            model: {
              value: _vm.options.filter[header.value],
              callback: function callback($$v) {
                _vm.$set(_vm.options.filter, header.value, $$v);
              },
              expression: "options.filter[header.value]"
            }
          })] : header.filter ? [_c('vx-input', {
            staticClass: "mx-1 my-2",
            class: {
              'v-input-border': _vm.options.search[header.value]
            },
            attrs: {
              "type": "text",
              "solo": "",
              "flat": "",
              "dense": "",
              "hide-details": "",
              "placeholder": "Search",
              "clearable": ""
            },
            on: {
              "input": function input($event) {
                return _vm.debounce();
              }
            },
            nativeOn: {
              "keyup": function keyup($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                return _vm.resetAndGetData();
              }
            },
            model: {
              value: _vm.options.search[header.value],
              callback: function callback($$v) {
                _vm.$set(_vm.options.search, header.value, $$v);
              },
              expression: "options.search[header.value]"
            }
          })] : void 0], 2);
        }), 0)];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            headers = _ref2.headers,
            isSelected = _ref2.isSelected,
            select = _ref2.select;
        return [_c('tr', _vm._l(headers, function (header, index) {
          var _item$accounts, _item$accounts2, _item$accounts$;

          return _c('td', {
            key: index,
            attrs: {
              "align": header.align
            }
          }, [header.component ? [header.component.name == 'accounts' ? [(item === null || item === void 0 ? void 0 : (_item$accounts = item.accounts) === null || _item$accounts === void 0 ? void 0 : _item$accounts.length) > 1 ? _c('div', {
            staticClass: "align-center d-flex justify-left"
          }, [_c('div', {
            staticClass: "profiles-images"
          }, [_vm._l(item === null || item === void 0 ? void 0 : item.accounts, function (account, index) {
            return [account._id ? _c('a', {
              key: index,
              on: {
                "click": function click($event) {
                  return _vm.$view('account', account._id);
                }
              }
            }, [_c('UserAvatar', {
              staticClass: "ma-1",
              attrs: {
                "size": 30,
                "square": false,
                "responsive": false,
                "user": account,
                "color": "primary"
              }
            })], 1) : _c('UserAvatar', {
              key: index,
              staticClass: "ma-1",
              attrs: {
                "size": 30,
                "square": false,
                "responsive": false,
                "user": {
                  name: _vm.BOOKING_SOURCE.MANUAL
                },
                "color": _vm.COLORS.GREY
              }
            })];
          })], 2), _vm.expanded.includes(item) ? _c('vx-icon', {
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.closeExpanded(item);
              }
            }
          }, [_vm._v("mdi-chevron-up")]) : _c('vx-icon', {
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.expanded.push(item);
              }
            }
          }, [_vm._v("mdi-chevron-down")])], 1) : item !== null && item !== void 0 && (_item$accounts2 = item.accounts) !== null && _item$accounts2 !== void 0 && _item$accounts2.length ? _c('div', {
            staticClass: "align-center d-flex justify-left"
          }, [item !== null && item !== void 0 && item.accounts[0]._id ? [_c('a', {
            on: {
              "click": function click($event) {
                return _vm.$view('account', item === null || item === void 0 ? void 0 : item.accounts[0]._id);
              }
            }
          }, [_c('UserAvatar', {
            staticClass: "ma-1",
            attrs: {
              "size": 30,
              "square": false,
              "responsive": false,
              "user": item === null || item === void 0 ? void 0 : item.accounts[0],
              "color": "primary"
            }
          }), _c('span', {
            staticClass: "px-2"
          }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$accounts$ = item.accounts[0]) === null || _item$accounts$ === void 0 ? void 0 : _item$accounts$.name))])], 1)] : [_c('UserAvatar', {
            key: index,
            staticClass: "ma-1",
            attrs: {
              "size": 30,
              "square": false,
              "responsive": false,
              "user": {
                name: _vm.BOOKING_SOURCE.MANUAL
              },
              "color": _vm.COLORS.GREY
            }
          }), _c('span', {
            staticClass: "px-2"
          }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.BOOKING_SOURCE.MANUAL)))])]], 2) : _c('div', {
            staticClass: "align-center d-flex justify-left"
          }, [_vm._v("No profile")])] : _c(header.component.name || header.component, _vm._b({
            tag: "component",
            attrs: {
              "value": _vm.getter(item, header),
              "item": item,
              "header": header,
              "options": _vm.options
            },
            on: {
              "callAction": _vm.callAction
            }
          }, 'component', header.component.props, false))] : header.value === 'data-table-select' ? [_c('v-simple-checkbox', {
            attrs: {
              "ripple": false,
              "color": "primary",
              "value": isSelected
            },
            on: {
              "input": function input($event) {
                return select($event);
              }
            }
          })] : header.tooltip ? [_c('vx-tooltip', {
            attrs: {
              "value": _vm.getter(item, header)
            }
          }, [_vm._v(_vm._s(_vm.getter(item, header)))])] : [_vm._v(" " + _vm._s(_vm.getter(item, header)) + " ")]], 2);
        }), 0)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          staticClass: "pa-5",
          attrs: {
            "colspan": _vm.dataTable.headers.length
          }
        }, [_c('div', {
          staticClass: "float-right",
          style: {
            width: _vm.breakpoint.mdAndDown ? '41%' : '43%'
          }
        }, [_c('Profiles', {
          attrs: {
            "profiles": item.accounts
          }
        })], 1)])];
      }
    }], null, true),
    model: {
      value: _vm.dataTable.value,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "value", $$v);
      },
      expression: "dataTable.value"
    }
  }), _c('MergeRecord', {
    attrs: {
      "selected-row": _vm.selectedRow,
      "options": _vm.mergeOptions
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.resetAndGetData();
      },
      "reject": function reject($event) {
        _vm.dialog.merge = false;
      }
    },
    model: {
      value: _vm.dialog.merge,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "merge", $$v);
      },
      expression: "dialog.merge"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }