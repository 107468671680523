var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-chip', {
    staticClass: "font-bold",
    attrs: {
      "outlined": "",
      "small": "",
      "color": _vm.color[_vm.value]
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }