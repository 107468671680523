<template>
  <v-icon v-if="value != 'N/A'">{{ value }}</v-icon>
  <div v-else>{{ value }}</div>
</template>

<script>
export default {
  name: 'DtIcon',
  props: {
    value: String,
  },
};
</script>
