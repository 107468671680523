<template>
  <div :key="item._id">
    <v-chip v-if="item.type !== EVENT_TYPE.BLOCKED" outlined small :color="status.color">
      <BookingStatus :module-name="moduleName" admin :color="false" :booking="item" @status="getStatus" />
    </v-chip>
    <span v-else>N/A</span>
  </div>
</template>

<script>
import { BookingStatus } from '@tutti/components';
import { EVENT_TYPE } from '@tutti/constants';
export default {
  name: 'DtBookingStatus',
  components: {
    BookingStatus,
  },
  props: {
    value: { type: null },
    item: { type: Object },
    header: { type: Object },
    moduleName: { type: String, default: undefined },
  },
  data() {
    return {
      status: {},
      EVENT_TYPE,
    };
  },

  methods: {
    getStatus(status) {
      this.status = status;
    },
  },
};
</script>
