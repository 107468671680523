<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="innerValue"
        dense
        outlined
        v-bind="$attrs"
        show-current
        readonly
        append-icon="mdi-calendar-range"
        v-on="on"
        @click:append="menu = true"
      >
        <slot></slot>
      </v-text-field>
    </template>

    <v-date-picker ref="picker" v-model="innerValue" v-bind="$attrs"></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'VxDatePicker',
  props: {
    rules: { type: Object, default: () => ({}) },
    value: { type: null, required: true },
    year: { type: Boolean, default: false },
  },
  data: () => ({
    menu: false,
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.menu = false;
      this.$emit('input', newVal);
      this.$emit('change', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal || '';
    },
    menu(val) {
      this.year && val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  created() {
    if (this.value) {
      this.innerValue = dayjs(this.value).format('YYYY-MM-DD');
    }
  },
};
</script>
