<template>
  <v-dialog v-model="dialog" width="800" @click:outside="onReject()">
    <v-card>
      <vx-list divider draggable :items="options.items" :item-title="options.itemTitle"></vx-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  data() {
    return {
      dialog: false,
      options: {},
    };
  },
  mounted() {
    this.$root.$on('list-dialog', options => {
      this.dialog = true;
      this.options = options;
    });
  },
  methods: {
    onReject() {
      if (typeof this.options.reject === 'function') {
        this.options.reject();
      }
    },
    onResolve() {
      this.dialog = false;
      if (typeof this.options.resolve === 'function') {
        this.options.resolve();
      }
    },
  },
};
</script>
