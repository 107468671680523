export default {
  methods: {
    $next(to) {
      this.$router.push(to);
    },
    $replace(to) {
      this.$router.replace(to);
    },
    $previous() {
      this.$router.go(-1);
    },

    $pushState(to) {
      const { href } = this.$router.resolve(to);
      window.history.pushState({}, null, href);
    },
    $replaceState(to) {
      const { href } = this.$router.resolve(to);
      window.history.replaceState({}, null, href);
    },

    $openBlankWindow(to) {
      if (to.href) {
        window.open(to.href, '_blank');
      } else {
        const route = this.$router.resolve(to);
        window.open(route.href, '_blank');
      }
    },

    $getPreviewURL(path) {
      return `${this.artistURL}${path}?preview=1`;
    },

    $toView(name, id, query) {
      return { name: `admin.${name}.view`, params: { id }, query };
    },
    $view(name, id, query) {
      this.$router.push(this.$toView(name, id, query));
    },

    $toViewVenue(venue = {}, venueType) {
      if (venueType === 'space') {
        return this.$toView('space', venue._id);
      } else if (venueType === 'location') {
        return this.$toView('location', venue._id);
      }
    },

    $toEdit(name, id, tab, query) {
      return { name: `admin.${name}.edit`, params: { id, tab }, query };
    },
    $edit(name, id, tab, query) {
      this.$router.push(this.$toEdit(name, id, tab, query));
    },

    $toEditAll(name, id) {
      return { name: `admin.${name}.edit.all`, query: { id } };
    },
    $editAll(name, id) {
      this.$router.push(this.$toEditAll(name, id));
    },

    $toAdd(name, query) {
      return { name: `admin.${name}.add`, query, params: { tab: 0 } };
    },
    $add(name, query) {
      this.$router.push(this.$toAdd(name, query));
    },

    $toList(name, filter) {
      return { name: `admin.${name}.list`, query: { filter } };
    },
    $list(name, filter) {
      this.$router.push(this.$toList(name, filter));
    },

    $toChildList(name, id) {
      return { name: `admin.${name}.list.id`, params: { id } };
    },
    $childList(name, id) {
      this.$router.push(this.$toChildList(name, id));
    },

    $toHistory(name, id) {
      return { name: `admin.${name}.history`, params: { id } };
    },
    $history(name, id) {
      this.$router.push(this.$toHistory(name, id));
    },
  },
};
