var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "app": "",
      "right": "",
      "multi-line": "",
      "color": _vm.color,
      "timeout": _vm.timeout
    },
    model: {
      value: _vm.snackbar,
      callback: function callback($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }