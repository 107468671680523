<template>
  <v-snackbar v-model="snackbar" app right multi-line :color="color" :timeout="timeout">
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      snackbar: false,
      text: '',
      color: '',
      timeout: 3000,
    };
  },
  mounted() {
    this.$bus.$on('success', text => {
      this.snackbar = true;
      this.color = 'success';
      this.text = text;
    });

    this.$bus.$on('error', text => {
      this.snackbar = true;
      this.color = 'error';
      this.text = text;
    });
  },
};
</script>
