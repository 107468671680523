<template>
  <v-simple-table class="profiles-list">
    <template v-slot:default>
      <tbody>
        <tr v-for="(profile, index) in profiles" :key="index">
          <td
            :style="{
              width: '27%',
            }"
          >
            <a v-if="profile._id" @click="$view('account', profile._id)">
              <UserAvatar :size="35" :square="false" :user="profile" :responsive="false" color="primary" class="ma-1" />
              <span class="px-2" @click="$view('account', profile._id)">{{ profile.name }}</span>
            </a>
            <template v-else>
              <UserAvatar
                :size="35"
                :square="false"
                :user="{ name: BOOKING_SOURCE.MANUAL }"
                :responsive="false"
                :color="COLORS.GREY"
                class="ma-1"
              />
              <span class="px-2">{{ BOOKING_SOURCE.MANUAL | capitalize }}</span>
            </template>
          </td>

          <td
            class="text-left"
            :style="{
              width: '14%',
            }"
          >
            {{ profile.totalSpent | price }}
          </td>
          <td
            class="text-right"
            :style="{
              width: '10%',
            }"
          >
            {{ profile.completedBookings }}
          </td>
          <td
            :class="breakpoint.lgAndUp && !profile.lastEnquiryAt && 'pr-14'"
            class="text-right"
            :style="{
              width: '15%',
            }"
          >
            {{ profile.lastEnquiryAt | dateTime }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { BOOKING_SOURCE, COLORS } from '@tutti/constants';
export default {
  name: 'Profiles',

  components: {
    UserAvatar: () => import('./UserAvatar.vue'),
  },

  props: {
    profiles: { type: Array, default: () => [] },
  },

  data() {
    return {
      BOOKING_SOURCE,
      COLORS,
    };
  },
};
</script>
<style scoped>
.profiles-list {
  border: 1px solid #eee !important;
}
</style>
