import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import { FeatureService } from '@tutti/services';

import modules from './modules';

Vue.use(Vuex);

const options = {
  key: 'admin',
};

if (process.env.VUE_APP_SECURE_LS == 'true') {
  const ls = new SecureLS({
    encodingType: process.env.VUE_APP_SECURE_LS_ENCODING_TYPE,
    isCompression: process.env.VUE_APP_SECURE_LS_COMPRESSION,
    encryptionSecret: process.env.VUE_APP_SECURE_LS_ENCRYPTION_SECRET,
  });

  options.storage = {
    getItem: key => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: key => ls.remove(key),
  };
}

export default new Vuex.Store({
  state: {
    state: {},
    features: [],
  },
  mutations: {
    SET_STATE(state, value) {
      state.state = value;
    },
    SET_FEATURES(state, features) {
      state.features = features;
    },
  },
  actions: {
    setFilter({ commit }, value) {
      commit('SET_STATE', value);
    },
    async setFeatures({ commit }) {
      const response = await FeatureService.get();
      if (response) {
        commit('SET_FEATURES', response.data);
      }
    },
  },
  modules,
  plugins: [pathify.plugin, createPersistedState(options)],
});
