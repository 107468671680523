export default {
  methods: {
    async $login(response) {
      if (response.token) {
        localStorage.setItem(process.env.VUE_APP_AUTH_TOKEN_KEY, response.token);
        this.$cookies.set(process.env.VUE_APP_AUTH_TOKEN_KEY, response.token);
      }

      if (response.user) {
        this.$store.set('auth/user', response.user);
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else {
          this.$router.push({ name: 'admin.dashboard' });
        }
      }
    },

    $logout() {
      localStorage.removeItem(process.env.VUE_APP_AUTH_TOKEN_KEY);
      this.$cookies.remove(process.env.VUE_APP_AUTH_TOKEN_KEY);
      this.$store.set('auth/user', null);

      this.$router.push({ name: 'admin.login' });
    },
    $hasPermission(permission) {
      return this.$user?.permissions?.includes(permission);
    },
  },
};
