var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "dense": "",
            "outlined": "",
            "show-current": "",
            "readonly": "",
            "append-icon": "mdi-calendar-range"
          },
          on: {
            "click:append": function clickAppend($event) {
              _vm.menu = true;
            }
          },
          model: {
            value: _vm.innerValue,
            callback: function callback($$v) {
              _vm.innerValue = $$v;
            },
            expression: "innerValue"
          }
        }, 'v-text-field', _vm.$attrs, false), on), [_vm._t("default")], 2)];
      }
    }], null, true),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-date-picker', _vm._b({
    ref: "picker",
    model: {
      value: _vm.innerValue,
      callback: function callback($$v) {
        _vm.innerValue = $$v;
      },
      expression: "innerValue"
    }
  }, 'v-date-picker', _vm.$attrs, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }