import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { COLORS } from '@tutti/constants';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: COLORS.PRIMARY,
        secondary: '#eeeeee',
        dark: '#616161',
        darkOnly: '#616161',
        light: COLORS.LIGHT,
        lightOnly: COLORS.LIGHT,
      },
      dark: {
        primary: COLORS.PRIMARY,
        // secondary: '#616161',
        dark: COLORS.LIGHT,
        darkOnly: COLORS.DARK,
        light: COLORS.DARK,
        lightOnly: COLORS.DARK,
      },
    },
  },
});
