<template>
  <div>
    <vx-img class="my-2 rounded border-primary-1" rectangle :src="value" />

    <!-- <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-text>
          <div v-html="item.html"></div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- <vx-lightbox v-model="lightbox" :items="[item]">
      <template v-slot:default="obj">
        <div class="center w-90 w-md-90 w-sm-75">
          <div class="position-relative">
            <v-progress-circular class="center" size="64" indeterminate color="white" />
            <div v-html="item.html"></div>
          </div>
        </div>
      </template>
    </vx-lightbox> -->
  </div>
</template>

<script>
import { ScriptService } from '@tutti/services';

export default {
  name: 'DtIframe',
  props: {
    value: String,
    item: { type: Object },
  },
  data() {
    return {
      lightbox: null,
      dialog: false,
    };
  },
  async mounted() {
    await ScriptService.loadScript('https://cdn.iframe.ly/embed.js');
  },
  methods: {
    onClick() {
      this.lightbox = 0;
      this.dialog = true;
    },
  },
};
</script>
