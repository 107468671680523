<template>
  <div>
    <span v-if="value !== 'N/A' && hasPermission && (getter ? !!getter(item) : true)">
      <router-link
        v-if="value"
        :title="value.name || value.displayName || value.bookingNumber || value"
        :to="getter ? getter(item) : $toView(name, value._id || item._id)"
      >
        {{ value.name || value.displayName || value.bookingNumber || value }}
      </router-link>
    </span>
    <span v-else-if="value == 'N/A' && header.value == 'from' && item.type == 'tutti-commission'"> Tutti </span>
    <span v-else-if="value != 'N/A' && header.value == 'type'"> {{ INVOICE_TYPE_VALUE[item.type] }} </span>
    <span v-else-if="value">
      {{ value.name || value.displayName || value.bookingNumber || value }}
    </span>
  </div>
</template>

<script>
import { INVOICE_TYPE_VALUE } from '@tutti/constants';
export default {
  name: 'DtView',
  props: {
    item: { type: Object },
    header: { type: Object },
    name: { type: String },
    value: null,
    getter: { type: Function },
    permission: { type: [String, Function], default: '' },
  },
  data() {
    return {
      INVOICE_TYPE_VALUE,
    };
  },
  computed: {
    hasPermission() {
      let _permission = '';
      if (typeof this.permission === 'function') {
        _permission = this.permission(this.item);
      } else {
        _permission = this.permission;
      }
      return this.$user?.permissions?.includes(_permission);
    },
  },
};
</script>
