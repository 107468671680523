<template>
  <div>
    <v-simple-checkbox
      v-model="item[header.value]"
      :ripple="false"
      color="primary"
      :disabled="disabled"
      @input="onChangeInput()"
    ></v-simple-checkbox>
  </div>
</template>

<script>
import { UtilityService } from '@tutti/services';

export default {
  name: 'DtCheckbox',

  props: {
    item: { type: Object },
    header: { type: Object },
    onChange: { type: Function },
    disabled: { type: Boolean, default: false },
    action: { type: Boolean, default: false },
  },

  methods: {
    async onChangeInput() {
      if (this.onChange) {
        this.onChange(this.item);
      } else if (this.action) {
        const res = await this.$confirm({
          title: UtilityService.generateMessage(this.$route.meta.name, this.header.value, this.item[this.header.value]),
        });

        if (res) {
          const items = [
            {
              _id: this.item._id,
              body: {
                [this.header.value]: this.item[this.header.value],
              },
            },
          ];

          this.$emit('callAction', items);
        } else {
          this.item[this.header.value] = !this.item[this.header.value];
        }
      }
    },
  },
};
</script>
