<template>
  <div>
    <v-chip outlined small class="font-bold" :color="VENUE_STATUS_COLOR[value]">
      {{ VENUE_STATUS_LABEL[value] }}
    </v-chip>
  </div>
</template>

<script>
import { VENUE_STATUS_LABEL, VENUE_STATUS_COLOR } from '@tutti/constants';

export default {
  name: 'DtVenueStatus',

  props: {
    value: { type: null },
    item: { type: Object },
    header: { type: Object },
    onChange: { type: Function },
  },

  data() {
    return {
      VENUE_STATUS_LABEL,
      VENUE_STATUS_COLOR,
    };
  },
};
</script>
