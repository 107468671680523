<template>
  <v-app>
    <v-progress-linear :active="loading" indeterminate top fixed value="15"></v-progress-linear>

    <router-view class="secondary h-100"></router-view>

    <snackbar />
    <alert-dialog />
    <confirm-dialog />
    <list-dialog />

    <app-settings />
  </v-app>
</template>

<script>
import { sync } from 'vuex-pathify';
import { AuthService, StorageService } from '@tutti/services';

import Snackbar from '@/components/snackbar';
import ListDialog from '@/components/list-dialog';
import AlertDialog from '@/components/alert-dialog';
import ConfirmDialog from '@/components/confirm-dialog';
import AppSettings from '@/components/app-settings';

export default {
  name: 'App',

  components: {
    ListDialog,
    AlertDialog,
    ConfirmDialog,
    Snackbar,
    AppSettings,
  },

  computed: {
    ...sync('app', ['loading']),
  },

  created() {
    this.$store.dispatch('setFeatures');
    this.getVersion();
  },

  methods: {
    async getVersion() {
      const response = await AuthService.version();

      if (response) {
        const version = StorageService.getItem('version');
        if (version && version != response.data?.version) {
          window.location.reload();
        }

        StorageService.setItem('version', response.data.version);
      }
    },
  },
};
</script>

<style lang="scss" src="@/scss/index.scss"></style>
