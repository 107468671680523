var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-simple-checkbox', {
    attrs: {
      "ripple": false,
      "color": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.onChangeInput();
      }
    },
    model: {
      value: _vm.item[_vm.header.value],
      callback: function callback($$v) {
        _vm.$set(_vm.item, _vm.header.value, $$v);
      },
      expression: "item[header.value]"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }