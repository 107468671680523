<template>
  <div>
    <v-chip outlined small class="font-bold" :color="color[value]">
      {{ value }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'DtChip',

  props: {
    value: { type: null },
  },

  data() {
    return {
      color: {
        Public: 'success',
        Unlisted: 'error',
      },
    };
  },
};
</script>
