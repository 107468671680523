import qs from 'qs';
import Vue from 'vue';
import _ from 'lodash';
import VueRouter from 'vue-router';
import store from '@store';

import { EventService, UtilityService } from '@tutti/services';

import adminRoutes from '@admin/routes';

Vue.use(VueRouter);

const history = [];

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'home',
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@views/welcome.vue'),
    meta: {
      noAuth: true,
    },
  },
  ...adminRoutes,

  {
    path: '*',
    name: '404',
    component: () => import('@views/404.vue'),
    meta: {
      noAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(_.omitBy(query, x => UtilityService.isEmpty(x)));
    return result ? '?' + result : '';
  },
});

router.beforeEach((to, from, next) => {
  if (!to.params?.skip) {
    if (history.length > 10) {
      history.shift();
    }
    history.push({
      name: to.name,
    });
  }

  if (to.name != 'admin.login' && !to.meta.noAuth && !store.state.auth.user) {
    // next({ name: 'admin.login' });
    next();
  } else {
    next();
  }

  EventService.$emit('app-bar-title', null);
  EventService.$emit('breadcrumb', history);
});

export default router;
