var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "800"
    },
    on: {
      "click:outside": function clickOutside($event) {
        return _vm.onReject();
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('vx-list', {
    attrs: {
      "divider": "",
      "draggable": "",
      "items": _vm.options.items,
      "item-title": _vm.options.itemTitle
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }