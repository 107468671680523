var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.item._id
  }, [_vm.item.type !== _vm.EVENT_TYPE.BLOCKED ? _c('v-chip', {
    attrs: {
      "outlined": "",
      "small": "",
      "color": _vm.status.color
    }
  }, [_c('BookingStatus', {
    attrs: {
      "module-name": _vm.moduleName,
      "admin": "",
      "color": false,
      "booking": _vm.item
    },
    on: {
      "status": _vm.getStatus
    }
  })], 1) : _c('span', [_vm._v("N/A")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }