<template>
  <div class="d-flex">
    <template v-for="(action, index) in actions">
      <vx-btn
        :key="index"
        icon
        :tooltip="action.tooltip"
        @click.exact="action.click(item, {})"
        @click.ctrl="action.click(item, { ctrl: true })"
      >
        <v-icon>{{ action.icon }}</v-icon>
      </vx-btn>
    </template>
    <vx-dialog :key="dialog" v-model="dialog" width="760" @reject="() => (dialog = false)">
      <div v-if="generateLoading" class="text-center">Please wait generating invoice...</div>
      <div id="pdf"></div>
    </vx-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
  name: 'DtActions',
  props: {
    item: { type: Object },
    options: { type: Object },
    duplicate: { type: Function },
    deleteVenue: { type: Function },
    mergeRecords: { type: Function },
  },
  data() {
    return {
      actions: [],
      dialog: false,
      generateLoading: false,
    };
  },
  created() {
    if (this.options.action?.showChildList) {
      this.actions.push({
        tooltip: this.options.action.child + ' list',
        icon: 'mdi-clipboard-text-outline',
        click: this.onList,
      });
    }

    if (this.options.action?.showPreview) {
      this.actions.push({
        tooltip: 'Preview',
        icon: 'mdi-web',
        click: this.onPreview,
      });
    }

    if (!this.options.action?.hideView) {
      this.actions.push({
        tooltip: 'View',
        icon: 'mdi-eye-outline',
        click: this.onView,
      });
    }

    if (!this.options.action?.hideEdit) {
      this.actions.push({
        tooltip: 'Edit',
        icon: 'mdi-pencil-box-outline',
        click: this.onEdit,
      });
    }

    if (this.options.action?.showEditAll) {
      this.actions.push({
        tooltip: 'Edit all',
        icon: 'mdi-pencil-box-multiple-outline',
        click: this.onEditAll,
      });
    }

    if (this.options.action?.showDuplicate) {
      this.actions.push({
        tooltip: 'Duplicate',
        icon: 'mdi-content-duplicate',
        click: this.duplicate,
      });
    }

    if (this.options.action?.showHistory) {
      this.actions.push({
        tooltip: 'History',
        icon: 'mdi-history',
        click: this.onHistory,
      });
    }

    if (this.options.action?.downloadMedia) {
      this.actions.push({
        tooltip: 'Download all media',
        icon: 'mdi-inbox-arrow-down',
        click: this.onDownloadMedia,
      });
    }

    if (this.options.action?.downloadInvoice) {
      this.actions.push({
        tooltip: 'Download Invoice',
        icon: 'mdi-inbox-arrow-down',
        click: this.downloadInvoice,
      });
    }

    if (this.options.action?.viewInvoice) {
      this.actions.push({
        tooltip: 'View Invoice',
        icon: 'mdi-eye-outline',
        click: this.viewInvoice,
      });
    }

    if (this.options.action?.mergeRecords) {
      this.actions.push({
        tooltip: 'Merge Records',
        icon: 'mdi-source-merge',
        click: this.mergeRecords,
      });
    }

    if (this.options.action?.showDeleteVenue) {
      this.actions.push({
        tooltip: 'Delete space',
        icon: 'mdi-delete',
        click: this.deleteVenue,
      });
    }

    if (this.options.action?.actions?.length) {
      this.actions.push(...this.options.action?.actions);
    }
  },
  methods: {
    onView(item, { ctrl }) {
      if (typeof this.options.action?.onView === 'function') {
        this.options.action?.onView(item);
      } else {
        if (ctrl) {
          this.$openBlankWindow(this.$toView(this.options.route, item._id));
        } else {
          this.$view(this.options.route, item._id);
        }
      }
    },
    onPreview(item) {
      if (typeof this.options.action?.onPreview === 'function') {
        this.options.action?.onPreview(item);
      } else if (item.path) {
        window.open(this.$getPreviewURL(item.path), '_blank');
      }
    },
    onList(item, { ctrl }) {
      if (ctrl) {
        this.$openBlankWindow(this.$toChildList(this.options.action.child, item._id));
      } else {
        this.$childList(this.options.action.child, item._id);
      }
    },
    onEdit(item, { ctrl }) {
      if (ctrl) {
        this.$openBlankWindow(this.$toEdit(this.options.route, item._id));
      } else {
        this.$edit(this.options.route, item._id);
      }
    },
    onEditAll(item, { ctrl }) {
      if (ctrl) {
        this.$openBlankWindow(this.$toEditAll(this.options.action.child, item._id));
      } else {
        this.$editAll(this.options.action.child, item._id);
      }
    },
    onHistory(item, { ctrl }) {
      if (ctrl) {
        this.$openBlankWindow(this.$toHistory(this.options.route, item._id));
      } else {
        this.$history(this.options.route, item._id);
      }
    },
    async onDownloadMedia(item) {
      if (!item.media.length) {
        this.$error('No Media found ');
        return;
      }
      // if (item.media.length)

      const zip = new JSZip();

      const arrOfFiles = item.media.map(item => this.download(zip, item)); //create array of promises
      Promise.all(arrOfFiles)
        .then(() => {
          //when all promises resolved - save zip file
          zip.generateAsync({ type: 'blob' }).then(function (blob) {
            saveAs(blob, item.name);
          });
        })
        .catch(() => {
          this.$error('Error while download media');
        });
    },
    download(zip, item) {
      //download single file as blob and add it to zip archive
      return axios.get(item.url, { responseType: 'blob' }).then(resp => {
        zip.file(item.name, resp.data);
      });
    },
    async downloadInvoice(item) {
      if (window && window.easyinvoice) {
        const invoice = item.data;
        const result = await window.easyinvoice.createInvoice(invoice);
        const name = invoice?.products[0]?.description;
        window.easyinvoice.download(`${name}.pdf`, result.pdf);
      }
    },
    async viewInvoice(item) {
      if (window && window.easyinvoice) {
        const invoice = item.data;
        this.dialog = true;
        this.generateLoading = true;
        const element = document.getElementById('pdf');
        if (element) element.style.display = 'none';

        const result = await window.easyinvoice.createInvoice(invoice);
        if (result) {
          if (element) element.style.display = 'block';
          window.easyinvoice.render('pdf', result.pdf);
        }
        this.generateLoading = false;
      }
    },
  },
};
</script>
