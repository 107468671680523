var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', _vm._b({
    attrs: {
      "size": _vm.size,
      "color": _vm.color
    }
  }, 'v-avatar', _vm.$attrs, false), [_vm.src || _vm.$userHasImage(_vm.user) ? _c('vx-img', {
    staticClass: "border-radius-inherit",
    attrs: {
      "square": _vm.square,
      "responsive": _vm.responsive,
      "alt": _vm.user.displayName,
      "src": _vm.src || _vm.$getUserImage(_vm.user, '100')
    }
  }) : _c('span', {
    staticClass: "light--text text-h5"
  }, [_vm._v(_vm._s(_vm._f("initial")(_vm.user)))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }