var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.value !== 'N/A' && _vm.hasPermission && (_vm.getter ? !!_vm.getter(_vm.item) : true) ? _c('span', [_vm.value ? _c('router-link', {
    attrs: {
      "title": _vm.value.name || _vm.value.displayName || _vm.value.bookingNumber || _vm.value,
      "to": _vm.getter ? _vm.getter(_vm.item) : _vm.$toView(_vm.name, _vm.value._id || _vm.item._id)
    }
  }, [_vm._v(" " + _vm._s(_vm.value.name || _vm.value.displayName || _vm.value.bookingNumber || _vm.value) + " ")]) : _vm._e()], 1) : _vm.value == 'N/A' && _vm.header.value == 'from' && _vm.item.type == 'tutti-commission' ? _c('span', [_vm._v(" Tutti ")]) : _vm.value != 'N/A' && _vm.header.value == 'type' ? _c('span', [_vm._v(" " + _vm._s(_vm.INVOICE_TYPE_VALUE[_vm.item.type]) + " ")]) : _vm.value ? _c('span', [_vm._v(" " + _vm._s(_vm.value.name || _vm.value.displayName || _vm.value.bookingNumber || _vm.value) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }