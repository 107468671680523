var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vx-dialog', {
    attrs: {
      "width": "700",
      "title": _vm.title
    },
    on: {
      "reject": function reject($event) {
        return _vm.$emit('reject');
      }
    },
    model: {
      value: _vm.dialog.merge,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "merge", $$v);
      },
      expression: "dialog.merge"
    }
  }, [_c('v-row', [_c('v-card', [_vm.selectedRow.featureId ? _c('v-card-subtitle', {
    staticClass: "d-flex"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right text-sm-right",
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "8",
      "xl": "9"
    }
  }, [_c('b', [_vm._v("Feature: ")]), _vm._v(_vm._s(_vm.selectedRow.featureId.name) + " ")])], 1)], 1) : _vm._e(), _c('v-data-table', {
    attrs: {
      "show-select": true,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "options": _vm.dataTable.options,
      "server-items-length": _vm.dataTable.itemsLength,
      "item-key": _vm.dataTable.key,
      "footer-props": _vm.dataTable.footerProps
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      },
      "update:page": function updatePage($event) {
        return _vm.getData();
      },
      "update:items-per-page": function updateItemsPerPage($event) {
        return _vm.getData();
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.getData();
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
            headers = _ref.headers,
            isSelected = _ref.isSelected,
            select = _ref.select;
        return [_c('tr', _vm._l(headers, function (header, index) {
          return _c('td', {
            key: index,
            attrs: {
              "align": header.align
            }
          }, [header.component ? [_c(header.component.name || header.component, _vm._b({
            tag: "component",
            attrs: {
              "value": _vm.getter(item, header),
              "item": item,
              "header": header,
              "options": _vm.options
            },
            on: {
              "callAction": _vm.callAction
            }
          }, 'component', header.component.props, false))] : header.value === 'data-table-select' ? [_c('v-simple-checkbox', {
            attrs: {
              "ripple": false,
              "color": "primary",
              "value": isSelected
            },
            on: {
              "input": function input($event) {
                return select($event);
              }
            }
          })] : [_vm._v(" " + _vm._s(_vm.getter(item, header)) + " ")]], 2);
        }), 0)];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          staticClass: "pa-5",
          attrs: {
            "colspan": _vm.dataTable.headers.length
          }
        }, [_c('div', {
          staticClass: "float-right",
          style: {
            width: _vm.breakpoint.mdAndDown ? '41%' : '43%'
          }
        }, [_c('Profiles', {
          attrs: {
            "profiles": item.accounts
          }
        })], 1)])];
      }
    }]),
    model: {
      value: _vm.dataTable.value,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "value", $$v);
      },
      expression: "dataTable.value"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-spacer'), _c('div', [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.onMerge
    }
  }, [_vm._v(" Merge ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }