var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value === 'N/A' ? _c('span', [_vm._v(_vm._s(_vm.value))]) : _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://what3words.com/".concat(_vm.value)
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }