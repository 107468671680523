<template>
  <div>
    <vx-img class="my-2 rounded border-primary-1" rectangle preview :src="$getImage(value, 800)" />
  </div>
</template>

<script>
export default {
  name: 'DtImg',
  props: {
    value: String,
    item: { type: Object },
  },
};
</script>
