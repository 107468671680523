import Vue from 'vue';
import App from './app.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './components';
import './views/admin/components';
import './mixins';
import '@tutti/components/global';

// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import VueQuillEditor from 'vue-quill-editor';
Vue.use(VueQuillEditor, {
  modules: {
    syntax: false,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      // [{ header: '1' }, { header: '2' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }],
    ],
  },
});

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
if (process.env.VUE_APP_ADMIN) {
  // Vue.$cookies.set('admin', true);
}

Vue.config.productionTip = false;

import tutti from '@tutti';
import { EventService } from '@tutti/services';

tutti.use({
  baseURL: process.env.VUE_APP_API_URL,
  requestHandler() {
    store.set('app/loading', true);
  },
  responseHandler(response, options) {
    store.set('app/loading', false);
    if (!options.hideSuccessMessage && response.data.message) {
      EventService.$emit('success', response?.data?.message);
    }
  },
  responseErrorHandler(response, options) {
    store.set('app/loading', false);

    if (response && response.status == 401) {
      EventService.$emit('error', 'Your session has ended. Please log in again.');

      Vue.$cookies.remove(process.env.VUE_APP_AUTH_TOKEN_KEY);
      store.set('auth/user', null);
      const redirect = location.pathname.replace(process.env.VUE_APP_PUBLIC_PATH, '/');
      const href = (process.env.VUE_APP_PUBLIC_PATH || '/') + 'login?redirect=' + redirect;
      location.href = href;
    } else if (!options.hideErrorMessage && response?.data?.error) {
      EventService.$emit('error', response.data.error);
    } else {
      EventService.$emit('error', 'Something went wrong! try again.');
    }
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
