<template>
  <v-navigation-drawer v-model="drawer" style="z-index: 200" temporary fixed right>
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon @click="drawer = false">
          <v-icon> mdi-close </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-subheader>Theme</v-subheader>

    <v-btn-toggle v-model="theme" class="mx-4" mandatory active-class="primary light--text" @change="setTheme()">
      <vx-btn width="112"> Light </vx-btn>
      <vx-btn width="112"> Dark </vx-btn>
    </v-btn-toggle>

    <v-subheader>Colors</v-subheader>

    <v-color-picker
      v-model="primary"
      dot-size="24"
      mode="hexa"
      hide-canvas
      hide-sliders
      show-swatches
      swatches-max-height="200"
      @update:color="setColor()"
    ></v-color-picker>

    <v-switch v-model="dev" class="mt-4 mx-4" label="Developer mode"></v-switch>
    <!-- <v-switch class="mt-4 mx-4" v-model="breadcrumb" label="Breadcrumb"></v-switch> -->
  </v-navigation-drawer>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  name: 'NavigationDrawerSettings',

  data() {
    return {
      drawer: false,
    };
  },

  computed: {
    ...sync('app', ['theme', 'primary', 'dev', 'breadcrumb']),
  },

  mounted() {
    setTimeout(() => {
      this.setColor();
      this.setTheme();
    });

    this.$root.$on('app-drawer', () => {
      this.drawer = !this.drawer;
    });
  },

  methods: {
    setColor() {
      this.$vuetify.theme.themes.dark.primary = this.primary;
      this.$vuetify.theme.themes.light.primary = this.primary;
    },
    setTheme() {
      this.$vuetify.theme.dark = this.theme == 1;
    },
  },
};
</script>
