<template>
  <span v-if="value === 'N/A'">{{ value }}</span>
  <a v-else target="_blank" :href="`https://what3words.com/${value}`">
    {{ value }}
  </a>
</template>

<script>
export default {
  name: 'DtWhat3Words',
  props: {
    value: null,
  },
};
</script>
