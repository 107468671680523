<template>
  <v-card v-if="dev" class="mb-4">
    <v-card-title class="primary--text">
      JSON
      <v-spacer />
      <vx-btn color="primary" icon @click="$copy(value)">
        <v-icon>mdi-content-copy</v-icon>
      </vx-btn>
    </v-card-title>

    <v-card-text>
      <code>
        <pre>{{ value }}</pre>
      </code>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'VxCardJson',
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    value() {
      return JSON.stringify(this.item, null, 2);
    },
  },
};
</script>
