var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_vm._l(_vm.actions, function (action, index) {
    return [_c('vx-btn', {
      key: index,
      attrs: {
        "icon": "",
        "tooltip": action.tooltip
      },
      on: {
        "click": [function ($event) {
          if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) { return null; }
          return action.click(_vm.item, {});
        }, function ($event) {
          if (!$event.ctrlKey) { return null; }
          return action.click(_vm.item, {
            ctrl: true
          });
        }]
      }
    }, [_c('v-icon', [_vm._v(_vm._s(action.icon))])], 1)];
  }), _c('vx-dialog', {
    key: _vm.dialog,
    attrs: {
      "width": "760"
    },
    on: {
      "reject": function reject() {
        return _vm.dialog = false;
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.generateLoading ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v("Please wait generating invoice...")]) : _vm._e(), _c('div', {
    attrs: {
      "id": "pdf"
    }
  })])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }