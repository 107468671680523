<template>
  <v-avatar :size="size" :color="color" v-bind="$attrs">
    <vx-img
      v-if="src || $userHasImage(user)"
      class="border-radius-inherit"
      :square="square"
      :responsive="responsive"
      :alt="user.displayName"
      :src="src || $getUserImage(user, '100')"
    />
    <span v-else class="light--text text-h5">{{ user | initial }}</span>
  </v-avatar>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: { type: Object, required: true },
    size: { type: Number, default: 40 },
    src: { type: String, required: false },
    enabled: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    square: { type: Boolean, default: true },
    color: { type: String, default: 'grey' },
    responsive: { type: Boolean, default: true },
  },
};
</script>
