<template>
  <v-icon>{{ icon }}</v-icon>
</template>

<script>
export default {
  name: 'DtStrategy',
  props: {
    value: [Object, String],
  },
  computed: {
    icon() {
      if (this.value.google) {
        return 'mdi-google';
      } else if (this.value.facebook) {
        return 'mdi-facebook';
      } else if (this.value.twitter) {
        return 'mdi-twitter';
      } else {
        return 'mdi-email-outline';
      }
    },
  },
};
</script>
