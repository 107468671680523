var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "value": [0],
      "multiple": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "primary--text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _c('div', {
    staticClass: "flex-none"
  }, [!_vm.hideEdit && _vm.item ? _c('vx-btn', {
    attrs: {
      "color": "primary",
      "icon": "",
      "target": _vm.href ? '_blank' : '',
      "href": _vm.href,
      "to": _vm.href ? null : _vm.$toEdit(_vm.routeName || _vm.$route.meta.name, _vm.item._id, _vm.tab)
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-square-edit-outline")])], 1) : _vm._e()], 1)], 1), _c('v-expansion-panel-content', [_c('v-list', {
    staticClass: "v-list-item-link",
    attrs: {
      "dense": ""
    }
  }, [_vm.$slots.default ? _vm._t("default") : _vm._l(_vm.items, function (value, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "target": value.blank ? '_blank' : '',
        "href": value.href && value.href(_vm.item)
      }
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(value.title) + " ")]), _c('v-list-item-subtitle', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          !value.href && _vm.$copy(value.subtitle(_vm.item));
        }
      }
    }, [value.capitalize ? [_vm._v(_vm._s(_vm._f("capitalize")(value.subtitle(_vm.item))))] : value.boolean ? [_vm._v(_vm._s(value.subtitle(_vm.item) ? 'Yes' : 'No'))] : [_vm._v(" " + _vm._s(value.subtitle(_vm.item)) + " ")]], 2)], 1);
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }