<template>
  <v-dialog v-model="dialog" width="800" @click:outside="onClick(false)">
    <v-card class="pa-5">
      <v-card-title class="justify-center text-h5 font-bold" v-text="title"></v-card-title>
      <v-card-subtitle v-if="subtitle" class="text-center" v-text="subtitle"></v-card-subtitle>

      <v-card-actions class="mt-5">
        <div class="mx-auto">
          <vx-btn v-if="!hideCancelButton" class="mx-1 font-bold" large width="100" @click="onClick(false)"
            >Cancel</vx-btn
          >

          <vx-btn class="mx-1 font-bold" large width="100" color="primary" @click="onClick(true)">{{
            confirmButtonText
          }}</vx-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { MESSAGES } from '@tutti/constants';

export default {
  name: 'ConfirmDialog',

  data() {
    return {
      dialog: false,
      title: '',
      subtitle: null,
      callback: null,
      isWarning: false,
      confirmButtonText: null,
      hideCancelButton: false,
    };
  },
  mounted() {
    this.$root.$on('confirm', (options, callback) => {
      this.dialog = true;

      let message = MESSAGES[options.title || options] || options.title || options;

      if (options.count) {
        message = message.replace('{count}', options.count);
      }
      this.title = message;
      this.hideCancelButton = options.hideCancelButton || false;
      this.confirmButtonText = options.confirmButtonText || 'Yes';
      this.subtitle = options.subtitle;
      this.callback = callback;
    });
  },
  methods: {
    onClick(bool) {
      this.dialog = false;
      if (typeof this.callback === 'function') {
        this.callback(bool);
      }
    },
  },
};
</script>
