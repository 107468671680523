var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dev ? _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v(" JSON "), _c('v-spacer'), _c('vx-btn', {
    attrs: {
      "color": "primary",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.value);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-content-copy")])], 1)], 1), _c('v-card-text', [_c('code', [_c('pre', [_vm._v(_vm._s(_vm.value))])])])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }