var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vx-card-list', {
    attrs: {
      "title": "Meta info",
      "item": _vm.item,
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Created at ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.item.createdAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Updated at ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.item.updatedAt)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Created by ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$view('user', _vm.item.createdBy);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.createdBy) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Update by ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$view('user', _vm.item.updatedBy);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.updatedBy) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }