var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return false ? _c('v-list-item', {
    staticClass: "pa-0"
  }, [_c('v-list-item-avatar', {
    staticClass: "ma-0 mr-2",
    attrs: {
      "rounded": "",
      "size": _vm.size,
      "color": _vm.account.color || 'primary'
    }
  }, [_vm.$userHasImage(_vm.account) ? _c('div', {
    staticClass: "full-width full-height",
    staticStyle: {
      "padding": "2px"
    }
  }, [_c('vx-img', {
    attrs: {
      "alt": _vm.account.name,
      "height": _vm.size,
      "width": _vm.size,
      "src": _vm.$getAccountImage(_vm.account, '100')
    }
  })], 1) : _c('span', {
    staticClass: "lightOnly--text text-h5"
  }, [_vm._v(_vm._s(_vm._f("initial")(_vm.account)))])]), _c('v-list-item-title', {
    staticClass: "text-body-2"
  }, [_c('span', [_vm._v(_vm._s(_vm.account.name) + _vm._s(_vm.append))])])], 1) : _vm.account ? _c('router-link', {
    attrs: {
      "to": _vm.$toView('account', _vm.account._id)
    }
  }, [_vm._v(_vm._s(_vm.account.name))]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }