var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "800"
    },
    on: {
      "click:outside": function clickOutside($event) {
        return _vm.onClick(false);
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-5"
  }, [_c('v-card-title', {
    staticClass: "justify-center text-h5 font-bold",
    domProps: {
      "textContent": _vm._s(_vm.title)
    }
  }), _vm.subtitle ? _c('v-card-subtitle', {
    staticClass: "text-center",
    domProps: {
      "textContent": _vm._s(_vm.subtitle)
    }
  }) : _vm._e(), _c('v-card-actions', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "mx-auto"
  }, [!_vm.hideCancelButton ? _c('vx-btn', {
    staticClass: "mx-1 font-bold",
    attrs: {
      "large": "",
      "width": "100"
    },
    on: {
      "click": function click($event) {
        return _vm.onClick(false);
      }
    }
  }, [_vm._v("Cancel")]) : _vm._e(), _c('vx-btn', {
    staticClass: "mx-1 font-bold",
    attrs: {
      "large": "",
      "width": "100",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onClick(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.confirmButtonText))])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }