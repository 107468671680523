<template>
  <div>
    <vx-dialog v-model="dialog.merge" width="700" :title="title" @reject="$emit('reject')">
      <v-row>
        <v-card>
          <v-card-subtitle v-if="selectedRow.featureId" class="d-flex">
            <v-row>
              <v-col cols="12" sm="8" md="8" xl="9" class="text-right text-sm-right">
                <b>Feature: </b>{{ selectedRow.featureId.name }}
              </v-col>
            </v-row>
          </v-card-subtitle>

          <v-data-table
            v-model="dataTable.value"
            :show-select="true"
            :headers="dataTable.headers"
            :items="dataTable.items"
            :options.sync="dataTable.options"
            :server-items-length="dataTable.itemsLength"
            :item-key="dataTable.key"
            :footer-props="dataTable.footerProps"
            @update:page="getData()"
            @update:items-per-page="getData()"
            @update:sort-by="getData()"
            @update:sort-desc="getData()"
          >
            <template v-slot:item="{ item, headers, isSelected, select }">
              <tr>
                <td v-for="(header, index) in headers" :key="index" :align="header.align">
                  <template v-if="header.component">
                    <component
                      :is="header.component.name || header.component"
                      :value="getter(item, header)"
                      :item="item"
                      :header="header"
                      :options="options"
                      v-bind="header.component.props"
                      @callAction="callAction"
                    ></component>
                  </template>

                  <template v-else-if="header.value === 'data-table-select'">
                    <v-simple-checkbox :ripple="false" color="primary" :value="isSelected" @input="select($event)" />
                  </template>

                  <template v-else>
                    {{ getter(item, header) }}
                  </template>
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td :colspan="dataTable.headers.length" class="pa-5">
                <div class="float-right" :style="{ width: breakpoint.mdAndDown ? '41%' : '43%' }">
                  <Profiles :profiles="item.accounts" />
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <div>
            <vx-btn block color="primary" @click="onMerge"> Merge </vx-btn>
          </div>
        </v-col>
      </v-row>
    </vx-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { UtilityService } from '@tutti/services';
import { AttributeService } from '@tutti/services';

export default {
  name: 'MergeRecord',
  props: {
    value: { type: Boolean, required: true },
    selectedRow: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      title: 'Merge record',
      dialog: {
        merge: false,
      },
      dataTable: {
        key: '_id',
        options: {
          page: 1,
          itemsPerPage: 7,
        },
        items: [],
        headers: [],
        value: [],
        itemsLength: 0,
        loading: true,
        footerProps: {
          showCurrentPage: true,
          showFirstLastPage: true,
          itemsPerPageOptions: [7, 10, 15, 25, 50, 100],
        },
      },
    };
  },

  watch: {
    value(dialog) {
      this.dialog.merge = dialog;
    },
    selectedRow(e) {
      if (e) {
        this.dataTable.items = [];
        this.title = `Merge record with ${e.name}`;
        this.dataTable.headers = this.options.headers
          .filter(h => ['name'].includes(h.value))
          .map(h => {
            if (h.component) delete h.component;
            return h;
          });
        if (!this.options.hideHeader?.created) {
          this.dataTable.headers.push({
            text: this.options.header?.created || 'Created at',
            value: 'createdAt',
            width: '150px',
            component: 'dtDate',
          });
        }

        if (!this.options.hideHeader?.updated) {
          this.dataTable.headers.push({
            text: this.options.header?.updated || 'Updated at',
            value: 'updatedAt',
            width: '150px',
            component: 'dtDate',
          });
        }

        this.dataTable.headers.forEach(header => {
          header.width = header.width || '200px';
        });
        this.options.filter = this.options.filter || {};
        this.options.filter._id = { $ne: e._id };
        if (e.featureId) this.options.filter.featureId = e.featureId._id;
        if (!e.parentId) this.options.filter.parentId = { $eq: null };
        if (e.parentId) this.options.filter.parentId = e.parentId;
        this.dataTable.options.page = 1;
        this.getData();
      }
    },
  },

  created() {
    this.getData();
  },

  methods: {
    getter(item, header) {
      if (header.getter) {
        return header.getter(item) || 'N/A';
      }

      const value = this.$get(item, header.value);

      if (header.capitalize) {
        return _.capitalize(value);
      }

      if (value === undefined) {
        return 'N/A';
      }

      return value;
    },

    resetFilters() {
      this.options.filters.forEach(filter => {
        this.dataTable.options[filter.key] = [];
      });

      this.getData();
    },

    resetOptions() {
      this.dataTable.items = [];
      this.dataTable.options.page = 1;
    },

    getQuery() {
      return {
        filter: _.omitBy(this.options.filter, x => UtilityService.isEmpty(x)),
        page: this.dataTable.options.page,
        limit: this.dataTable.options.itemsPerPage,
      };
    },

    async getData() {
      this.dataTable.loading = true;
      const filter = this.getQuery();
      delete filter.sortBy;
      delete filter.sortDesc;

      if (this.options.collection) {
        const service = await import(`@tutti/services/${this.options.collection}.service`);

        const response = await service.default.getAll(filter);
        if (response) {
          const items = response.data.data || [];
          this.dataTable.itemsLength = response.data.count || 0;

          this.dataTable.items = items;
        }
      }

      this.dataTable.loading = false;
    },
    async callAction(items) {
      const service = await import(`@tutti/services/${this.options.collection}.service`);

      const response = await service.default.action({ items });

      if (response) {
        this.getData();
        this.dataTable.value = [];
        this.action = null;
      }
    },
    async onMerge() {
      if (this.dataTable.value.length) {
        const mergeIds = _.map(this.dataTable.value, '_id');
        const res = await AttributeService.mergeById(this.selectedRow._id, { mergeIds });
        if (res) {
          this.$emit('resolve');
        }
      }
    },
  },
};
</script>
