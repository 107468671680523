<template>
  <div v-if="setTimeZone && item[header.value] && item[header.value].date">
    {{ item[header.value].date | formatWithTimeZone(item[header.value].timezone, format) }}
  </div>
  <div v-else>
    {{ date }}
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'DtDate',
  props: {
    item: { type: Object },
    header: { type: Object },
    format: { type: String, default: 'MMM DD, YYYY' },
    setTimeZone: { type: Boolean, default: false },
  },
  computed: {
    date() {
      return this.item[this.header.value] ? dayjs(this.item[this.header.value]).format(this.format) : 'N/A';
    },
  },
};
</script>
