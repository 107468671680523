<template>
  <v-card v-if="options.filters?.length" class="mb-4">
    <v-card-title>
      <div>Filters</div>

      <v-spacer />

      <vx-btn icon small @click="toggle = !toggle">
        <v-icon small color="primary">mdi-chevron-down</v-icon>
      </vx-btn>

      <vx-btn icon small @click="resetFilters()">
        <v-icon small color="primary">mdi-reload</v-icon>
      </vx-btn>
    </v-card-title>

    <v-card-text v-show="!toggle" class="pb-4 pb-sm-8">
      <v-row>
        <v-col v-for="filter in options.filters" :key="filter.key" cols="12" sm="3">
          <template v-if="filter.type">
            <v-switch
              v-if="filter.type === 'switch'"
              v-model="options.filter[filter.key]"
              :label="filter.label"
              @change="onChange(filter)"
            />
          </template>
          <template v-else>
            <vx-input
              v-model="options.filter[filter.key]"
              type="autocomplete"
              :multiple="Object.hasOwnProperty.call(filter, 'multiple') ? filter.multiple : true"
              hide-details
              :class="{ 'v-input-border': options.filter[filter.key] && options.filter[filter.key].length }"
              :disabled="filter.disabled"
              :label="filter.label"
              :items="filter.items"
              :item-text="filter.itemText"
              :item-value="filter.itemValue"
              :clearable="Object.hasOwnProperty.call(filter, 'clearable') ? filter.clearable : true"
              @change="onChange(filter)"
            />
          </template>
        </v-col>

        <slot></slot>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DataTableFilters',
  props: {
    options: { type: Object },
  },
  data() {
    return {
      toggle: false,
    };
  },
  methods: {
    onChange(filter) {
      if (filter.onChange) {
        filter.onChange();
      }
      this.$emit('resetAndGetData');
    },
    resetFilters() {
      this.options.filters.forEach(filter => {
        this.options.filter[filter.key] = [];
      });

      this.$emit('getData');
    },
  },
};
</script>
