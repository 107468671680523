import { sync } from 'vuex-pathify';

export default {
  computed: {
    ...sync('app', ['dev']),
    $user() {
      return this.$store.state.auth.user;
    },
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    getState() {
      return this.$store.state.state[this.$route.name] || {};
    },
    setState(value) {
      this.$store.state.state[this.$route.name] = this.$store?.state?.state[this.$route.name] || {};
      this.$store.state.state[this.$route.name] = value;
      this.$store.dispatch('setFilter', this.$store.state.state);
    },

    $success(message) {
      this.$bus.$emit('success', message);
    },
    $error(message) {
      this.$bus.$emit('error', message);
    },
    $alert(options) {
      this.$root.$emit('alert', options);
    },
    $listDialog(options) {
      this.$root.$emit('list-dialog', options);
    },
    $confirm(options) {
      return new Promise(resolve => {
        this.$root.$emit('confirm', options, resolve);
      });
    },
    $warn(options) {
      //TODO need to update function
      return new Promise(resolve => {
        options.hideCancelButton = true;
        options.confirmButtonText = 'Ok';
        this.$root.$emit('confirm', options, resolve);
      });
    },
    $copy(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$success('Copying to clipboard was successful!');
        },
        err => {
          this.$error('Could not copy text: ', err);
        }
      );
    },

    $setTitle(name) {
      this.$bus.$emit('app-bar-title', name);
    },

    $validate(func = () => {}, previous, ref = 'form') {
      if (this.$refs[ref]) {
        if (this.$refs[ref].validate()) {
          func(previous);
        }
      } else {
        func(previous);
      }
    },

    $reset(ref = 'form') {
      if (this.$refs[ref]) {
        this.$refs[ref].reset();
      }
    },
  },
};
