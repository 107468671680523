<template>
  <v-expansion-panels :value="[0]" multiple class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header class="primary--text">
        {{ title }}
        <v-spacer />
        <div class="flex-none">
          <vx-btn
            v-if="!hideEdit && item"
            color="primary"
            icon
            :target="href ? '_blank' : ''"
            :href="href"
            :to="href ? null : $toEdit(routeName || $route.meta.name, item._id, tab)"
          >
            <v-icon color="primary">mdi-square-edit-outline</v-icon>
          </vx-btn>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-list dense class="v-list-item-link">
          <slot v-if="$slots.default"></slot>

          <v-list-item
            v-for="(value, index) in items"
            v-else
            :key="index"
            :target="value.blank ? '_blank' : ''"
            :href="value.href && value.href(item)"
          >
            <v-list-item-title> {{ value.title }} </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer" @click="!value.href && $copy(value.subtitle(item))">
              <template v-if="value.capitalize">{{ value.subtitle(item) | capitalize }}</template>
              <template v-else-if="value.boolean">{{ value.subtitle(item) ? 'Yes' : 'No' }}</template>
              <template v-else>
                {{ value.subtitle(item) }}
              </template>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'VxCardList',
  props: {
    title: { type: String, required: true },
    routeName: { type: String, required: false },
    item: { type: Object, required: false },
    items: { type: Array, required: false },
    tab: { type: Number, required: false },
    href: { type: String, required: false },
    hideEdit: { type: Boolean, default: false },
    cardProps: { type: Object, required: false },
    expand: { type: Boolean, default: false },
  },
};
</script>
