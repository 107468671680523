var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "200"
    },
    attrs: {
      "temporary": "",
      "fixed": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Settings")])], 1), _c('v-list-item-icon', {
          on: {
            "click": function click($event) {
              _vm.drawer = false;
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-divider'), _c('v-subheader', [_vm._v("Theme")]), _c('v-btn-toggle', {
    staticClass: "mx-4",
    attrs: {
      "mandatory": "",
      "active-class": "primary light--text"
    },
    on: {
      "change": function change($event) {
        return _vm.setTheme();
      }
    },
    model: {
      value: _vm.theme,
      callback: function callback($$v) {
        _vm.theme = $$v;
      },
      expression: "theme"
    }
  }, [_c('vx-btn', {
    attrs: {
      "width": "112"
    }
  }, [_vm._v(" Light ")]), _c('vx-btn', {
    attrs: {
      "width": "112"
    }
  }, [_vm._v(" Dark ")])], 1), _c('v-subheader', [_vm._v("Colors")]), _c('v-color-picker', {
    attrs: {
      "dot-size": "24",
      "mode": "hexa",
      "hide-canvas": "",
      "hide-sliders": "",
      "show-swatches": "",
      "swatches-max-height": "200"
    },
    on: {
      "update:color": function updateColor($event) {
        return _vm.setColor();
      }
    },
    model: {
      value: _vm.primary,
      callback: function callback($$v) {
        _vm.primary = $$v;
      },
      expression: "primary"
    }
  }), _c('v-switch', {
    staticClass: "mt-4 mx-4",
    attrs: {
      "label": "Developer mode"
    },
    model: {
      value: _vm.dev,
      callback: function callback($$v) {
        _vm.dev = $$v;
      },
      expression: "dev"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }