<template>
  <v-list-item v-if="false" class="pa-0">
    <v-list-item-avatar rounded :size="size" :color="account.color || 'primary'" class="ma-0 mr-2">
      <!-- <v-avatar rounded :size="size" :color="account.color || 'primary'" v-bind="$attrs"> -->
      <div v-if="$userHasImage(account)" class="full-width full-height" style="padding: 2px">
        <vx-img :alt="account.name" :height="size" :width="size" :src="$getAccountImage(account, '100')" />
      </div>
      <span v-else class="lightOnly--text text-h5">{{ account | initial }}</span>
      <!-- </v-avatar> -->
    </v-list-item-avatar>

    <v-list-item-title class="text-body-2">
      <span>{{ account.name }}{{ append }}</span>
    </v-list-item-title>
  </v-list-item>

  <router-link v-else-if="account" :to="$toView('account', account._id)">{{ account.name }}</router-link>
</template>

<script>
export default {
  name: 'DtAccountName',
  props: {
    item: { type: Object, required: true },
    // value: { type: Object, required: true },
    field: { type: String, required: false },
    size: { type: Number, default: 35 },
    enabled: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    count: { type: Number, required: false },
    append: { type: String, default: '' },
  },
  computed: {
    account() {
      return this.field ? this.item[this.field] : this.item;
    },
  },
};
</script>
