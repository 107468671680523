<template>
  <vx-card-list title="Meta info" :item="item" hide-edit>
    <v-list-item>
      <v-list-item-title> Created at </v-list-item-title>
      <v-list-item-subtitle>
        {{ item.createdAt | dateTime }}
      </v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-title> Updated at </v-list-item-title>
      <v-list-item-subtitle> {{ item.updatedAt | dateTime }} </v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-title> Created by </v-list-item-title>
      <v-list-item-subtitle class="cursor-pointer info--text" @click="$view('user', item.createdBy)">
        {{ item.createdBy }}
      </v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-title> Update by </v-list-item-title>
      <v-list-item-subtitle class="cursor-pointer info--text" @click="$view('user', item.updatedBy)">
        {{ item.updatedBy }}
      </v-list-item-subtitle>
    </v-list-item>
  </vx-card-list>
</template>

<script>
export default {
  name: 'VxCardListMeta',
  props: {
    item: { type: Object, required: true },
  },
};
</script>
