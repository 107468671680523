var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-simple-table', {
    staticClass: "profiles-list",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', _vm._l(_vm.profiles, function (profile, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            style: {
              width: '27%'
            }
          }, [profile._id ? _c('a', {
            on: {
              "click": function click($event) {
                return _vm.$view('account', profile._id);
              }
            }
          }, [_c('UserAvatar', {
            staticClass: "ma-1",
            attrs: {
              "size": 35,
              "square": false,
              "user": profile,
              "responsive": false,
              "color": "primary"
            }
          }), _c('span', {
            staticClass: "px-2",
            on: {
              "click": function click($event) {
                return _vm.$view('account', profile._id);
              }
            }
          }, [_vm._v(_vm._s(profile.name))])], 1) : [_c('UserAvatar', {
            staticClass: "ma-1",
            attrs: {
              "size": 35,
              "square": false,
              "user": {
                name: _vm.BOOKING_SOURCE.MANUAL
              },
              "responsive": false,
              "color": _vm.COLORS.GREY
            }
          }), _c('span', {
            staticClass: "px-2"
          }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.BOOKING_SOURCE.MANUAL)))])]], 2), _c('td', {
            staticClass: "text-left",
            style: {
              width: '14%'
            }
          }, [_vm._v(" " + _vm._s(_vm._f("price")(profile.totalSpent)) + " ")]), _c('td', {
            staticClass: "text-right",
            style: {
              width: '10%'
            }
          }, [_vm._v(" " + _vm._s(profile.completedBookings) + " ")]), _c('td', {
            staticClass: "text-right",
            class: _vm.breakpoint.lgAndUp && !profile.lastEnquiryAt && 'pr-14',
            style: {
              width: '15%'
            }
          }, [_vm._v(" " + _vm._s(_vm._f("dateTime")(profile.lastEnquiryAt)) + " ")])]);
        }), 0)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }