import { make } from 'vuex-pathify';
import { COLORS } from '@tutti/constants';

const state = {
  loading: false,
  theme: 0,
  primary: COLORS.PRIMARY,
  dev: false,
  breadcrumb: false,
};

const mutations = make.mutations(state);

const actions = make.actions('items');

const getters = make.getters(state);

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
