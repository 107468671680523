var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-progress-linear', {
    attrs: {
      "active": _vm.loading,
      "indeterminate": "",
      "top": "",
      "fixed": "",
      "value": "15"
    }
  }), _c('router-view', {
    staticClass: "secondary h-100"
  }), _c('snackbar'), _c('alert-dialog'), _c('confirm-dialog'), _c('list-dialog'), _c('app-settings')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }