var render = function () {
  var _vm$options$filters;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$options$filters = _vm.options.filters) !== null && _vm$options$filters !== void 0 && _vm$options$filters.length ? _c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', [_c('div', [_vm._v("Filters")]), _c('v-spacer'), _c('vx-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.toggle = !_vm.toggle;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-chevron-down")])], 1), _c('vx-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.resetFilters();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-reload")])], 1)], 1), _c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.toggle,
      expression: "!toggle"
    }],
    staticClass: "pb-4 pb-sm-8"
  }, [_c('v-row', [_vm._l(_vm.options.filters, function (filter) {
    return _c('v-col', {
      key: filter.key,
      attrs: {
        "cols": "12",
        "sm": "3"
      }
    }, [filter.type ? [filter.type === 'switch' ? _c('v-switch', {
      attrs: {
        "label": filter.label
      },
      on: {
        "change": function change($event) {
          return _vm.onChange(filter);
        }
      },
      model: {
        value: _vm.options.filter[filter.key],
        callback: function callback($$v) {
          _vm.$set(_vm.options.filter, filter.key, $$v);
        },
        expression: "options.filter[filter.key]"
      }
    }) : _vm._e()] : [_c('vx-input', {
      class: {
        'v-input-border': _vm.options.filter[filter.key] && _vm.options.filter[filter.key].length
      },
      attrs: {
        "type": "autocomplete",
        "multiple": Object.hasOwnProperty.call(filter, 'multiple') ? filter.multiple : true,
        "hide-details": "",
        "disabled": filter.disabled,
        "label": filter.label,
        "items": filter.items,
        "item-text": filter.itemText,
        "item-value": filter.itemValue,
        "clearable": Object.hasOwnProperty.call(filter, 'clearable') ? filter.clearable : true
      },
      on: {
        "change": function change($event) {
          return _vm.onChange(filter);
        }
      },
      model: {
        value: _vm.options.filter[filter.key],
        callback: function callback($$v) {
          _vm.$set(_vm.options.filter, filter.key, $$v);
        },
        expression: "options.filter[filter.key]"
      }
    })]], 2);
  }), _vm._t("default")], 2)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }