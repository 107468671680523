<template>
  <a @click="$copy(value)">{{ value }}</a>
</template>

<script>
export default {
  name: 'DtLink',
  props: {
    value: null,
    copy: { type: Boolean, default: false },
  },
};
</script>
