// import userRoutes from './views/user/routes';
import layout from '@/layouts/index.vue';

function generate(options = {}) {
  const name = options.name;
  const path = options.path;
  const title = options.title;
  const list = `admin.${name}.list`;

  const children = [
    {
      path: 'add/new',
      name: `admin.${name}.add`,
      component: () => import(`./views/${name}/edit/index.vue`),
      meta: {
        name,
        title,
        list,
        hideUser: true,
      },
      props: {
        edit: false,
        editAll: false,
      },
    },
    {
      path: 'view/:id',
      name: `admin.${name}.view`,
      component: () => import(`./views/${name}/view/index.vue`),
      meta: {
        name,
        title,
        list,
      },
    },

    {
      path: 'list',
      name: `admin.${name}.list`,
      component: () => import(`./views/${name}/list/index.vue`),
      meta: {
        name,
        title,
      },
    },
  ];

  if (options.childList) {
    children.push({
      path: 'list/:id',
      name: `admin.${name}.list.id`,
      component: () => import(`./views/${name}/list/index.vue`),
      meta: {
        name,
        title,
        list,
        paramsKey: options.childList,
      },
    });
  }

  if (options.editAll) {
    children.push({
      path: 'edit/all/:tab?',
      name: `admin.${name}.edit.all`,
      component: () => import(`./views/${name}/edit/index.vue`),
      meta: {
        name,
        title,
        list,
        hideUser: true,
      },
      props: {
        edit: false,
        editAll: true,
      },
    });
  }

  children.push({
    path: 'edit/:id/:tab?',
    name: `admin.${name}.edit`,
    component: () => import(`./views/${name}/edit/index.vue`),
    meta: {
      name,
      title,
      list,
      hideUser: true,
    },
    props: {
      edit: true,
      editAll: false,
    },
  }); // push after editAll

  if (options.history) {
    children.push({
      path: 'history/:id',
      name: `admin.${name}.history`,
      component: () => import(`./views/${name}/history/index.vue`),
      meta: {
        name,
        title,
        list,
      },
    });
  }

  if (options.grid) {
    children.push({
      path: 'grid',
      name: `admin.${name}.grid`,
      component: () => import(`./views/${name}/grid/index.vue`),
      meta: {
        name,
        title,
      },
    });
  }

  if (options.all) {
    children.push({
      path: 'all',
      name: `admin.${name}.all`,
      component: () => import(`./views/${name}/all/index.vue`),
      meta: {
        name,
        title,
      },
    });
  }

  if (options.duplicates) {
    children.push({
      path: 'duplicate',
      name: `admin.${name}.duplicate`,
      component: () => import(`./views/${name}/duplicate/index.vue`),
      meta: {
        name,
        title,
      },
    });
  }

  return {
    path: (path || name).replace('-', '/'),
    name: `admin.${name}`,
    redirect: `/${name.replace('-', '/')}/view`,
    component: layout,
    children,
    meta: {
      name,
      title,
    },
  };
}

export default [
  {
    path: '/test',
    name: 'admin.test',
    component: () => import('./test.vue'),
  },
  {
    path: '/login',
    name: 'admin.login',
    component: () => import('./views/auth/login.vue'),
  },
  {
    path: '/',
    name: 'admin',
    component: () => import('./layout.vue'),
    children: [
      {
        path: '/',
        name: 'admin.root',
        redirect: '/dashboard',
      },
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: () => import('./views/dashboard/index.vue'),
        meta: { title: 'Dashboard' },
      },
      {
        path: 'icon/list',
        name: 'admin.icon',
        component: () => import('./views/icon/index.vue'),
        meta: { title: 'Icons' },
      },

      // USER
      generate({ name: 'user', title: 'Users' }),
      generate({ name: 'account', path: 'profile', title: 'Profiles', childList: 'ownerId' }),
      generate({ name: 'contact', title: 'Contacts' }),
      generate({ name: 'referral', title: 'Referrals' }),
      generate({ name: 'voucher', title: 'Vouchers' }),
      generate({ name: 'invitation', title: 'Invitations' }),

      // Listing
      generate({ name: 'space', title: 'Spaces', history: true, childList: 'locationId' }),
      generate({ name: 'location', title: 'Locations', history: true, childList: 'accountId' }),
      generate({ name: 'message', title: 'Messages' }),
      generate({ name: 'booking', title: 'Bookings', childList: 'spaceId' }),
      generate({ name: 'payment', title: 'Payments' }),
      generate({ name: 'invoice', title: 'Invoice' }),
      generate({ name: 'review', title: 'Reviews', childList: 'bookingId' }),
      generate({ name: 'content', title: 'Contents', all: true }),

      // FILE
      generate({ name: 'file', title: 'Files', grid: true, duplicates: true }),

      // ADMIN
      generate({ name: 'tag', title: 'Tags' }),
      generate({ name: 'role', title: 'Roles' }),
      generate({ name: 'feature', title: 'Features' }),
      generate({ name: 'attribute', title: 'Attributes', childList: 'featureId' }),
      generate({ name: 'question', title: 'Questions' }),
      generate({ name: 'search', title: 'Searches' }),
      generate({ name: 'template', title: 'Templates' }),
      generate({ name: 'booking_status', title: 'Enquiry Statuses' }),
      generate({ name: 'enquiry_type', title: 'Enquiry Types' }),

      // CMS
      generate({ name: 'page', title: 'Pages', editAll: true, childList: 'schemaId' }),
      generate({ name: 'page-schema', title: 'Pages schemas' }),
      generate({ name: 'component', title: 'Components', childList: 'schemaId' }),
      generate({ name: 'component-schema', title: 'Components schemas' }),
      generate({ name: 'collection', title: 'Collections', editAll: true, childList: 'schemaId' }),
      generate({ name: 'collection-schema', title: 'Collections schemas' }),
      generate({ name: 'ab-testcase', title: 'AB Tescase' }),

      // TFL
      generate({ name: 'route', title: 'Routes' }),
      generate({ name: 'place', title: 'Places' }),
    ],
  },
];
