<template>
  <div>
    {{ value | price }}
  </div>
</template>

<script>
export default {
  name: 'DtPrice',
  props: {
    value: { type: null },
  },
};
</script>
