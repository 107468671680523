var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.value);
      }
    }
  }, [_vm._v(_vm._s(_vm.value))]);
}
var staticRenderFns = []

export { render, staticRenderFns }