var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vx-img', {
    staticClass: "my-2 rounded border-primary-1",
    attrs: {
      "rectangle": "",
      "src": _vm.value
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }