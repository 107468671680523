<template>
  <div>{{ size }}</div>
</template>

<script>
export default {
  name: 'DtSize',
  props: {
    value: Number,
  },
  computed: {
    size() {
      const kb = Math.round(this.value / 1000);
      if (kb > 1000) {
        return `${Math.round(kb / 1000)} mb`;
      } else {
        return `${kb} kb`;
      }
    },
  },
};
</script>
