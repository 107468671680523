<template>
  <div>
    <Filters :options="options" @getData="getData()" @resetAndGetData="resetAndGetData()">
      <slot name="filter"></slot>
    </Filters>

    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col
            v-if="options.export || options.addNew || actionsLength || options.sync"
            cols="12"
            sm="8"
            md="8"
            xl="9"
            class="text-left text-sm-right"
          >
            <v-row>
              <v-col v-if="actionsLength" cols="3">
                <vx-menu
                  v-model="action"
                  :btn-props="{ block: true, outlined: true, disabled: !dataTable.value.length }"
                  label="Actions"
                  :items="options.actions"
                  @click="onChangeAction"
                />
              </v-col>
              <v-col v-if="options.export" cols="3">
                <vx-btn block outlined color="primary" :disabled="!dataTable.itemsLength" @click="exportAll()">
                  Export
                </vx-btn>
              </v-col>
              <slot name="addOptions"></slot>
              <v-col v-if="options.addNew" cols="3">
                <vx-btn block color="primary" @click="addNew()">Add new</vx-btn>
              </v-col>
              <v-col v-if="options.sync && dev" cols="3">
                <vx-btn block text color="primary" @click="sync()">Sync </vx-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-spacer />

          <v-col cols="12" sm="4" md="4" xl="3">
            <vx-input
              v-model="options.search.text"
              type="text"
              :class="{ 'v-input-border': options.search.text }"
              hide-details
              label="Search"
              @input="debounce()"
              @keyup.native.enter="resetAndGetData()"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="dataTable.value"
        :show-select="actionsLength"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :options.sync="dataTable.options"
        :server-items-length="dataTable.itemsLength"
        :item-key="dataTable.key"
        :footer-props="dataTable.footerProps"
        :expanded.sync="expanded"
        @update:page="getData()"
        @update:items-per-page="getData()"
        @update:sort-by="getData()"
        @update:sort-desc="getData()"
      >
        <template v-slot:[`body.prepend`]="{ headers }">
          <tr>
            <td v-for="(header, index) in headers" :key="index">
              <template v-if="header.filter && header.filter.type">
                <vx-input
                  v-model="options.filter[header.value]"
                  :type="header.filter.type"
                  multiple
                  hide-details
                  :class="{ 'v-input-border': options.filter[header.value] && options.filter[header.value].length }"
                  :disabled="header.filter.disabled"
                  :label="header.filter.label"
                  :items="header.filter.items"
                  :item-text="header.filter.itemText"
                  :item-value="header.filter.itemValue"
                  @change="resetAndGetData()"
                  @click:clear="resetAndGetData()"
                />
              </template>

              <template v-else-if="header.filter">
                <vx-input
                  v-model="options.search[header.value]"
                  type="text"
                  class="mx-1 my-2"
                  :class="{ 'v-input-border': options.search[header.value] }"
                  solo
                  flat
                  dense
                  hide-details
                  placeholder="Search"
                  clearable
                  @input="debounce()"
                  @keyup.native.enter="resetAndGetData()"
                />
              </template>

              <template v-else> </template>
            </td>
          </tr>
        </template>

        <template v-slot:item="{ item, headers, isSelected, select }">
          <tr>
            <td v-for="(header, index) in headers" :key="index" :align="header.align">
              <template v-if="header.component">
                <template v-if="header.component.name == 'accounts'" class="d-flex">
                  <div v-if="item?.accounts?.length > 1" class="align-center d-flex justify-left">
                    <div class="profiles-images">
                      <template v-for="(account, index) in item?.accounts">
                        <a v-if="account._id" :key="index" @click="$view('account', account._id)">
                          <UserAvatar
                            :size="30"
                            :square="false"
                            :responsive="false"
                            :user="account"
                            color="primary"
                            class="ma-1"
                          />
                        </a>
                        <UserAvatar
                          v-else
                          :key="index"
                          :size="30"
                          :square="false"
                          :responsive="false"
                          :user="{ name: BOOKING_SOURCE.MANUAL }"
                          :color="COLORS.GREY"
                          class="ma-1"
                        />
                      </template>
                    </div>
                    <vx-icon v-if="expanded.includes(item)" @click.stop="closeExpanded(item)">mdi-chevron-up</vx-icon>
                    <vx-icon v-else @click.stop="expanded.push(item)">mdi-chevron-down</vx-icon>
                  </div>
                  <div v-else-if="item?.accounts?.length" class="align-center d-flex justify-left">
                    <template v-if="item?.accounts[0]._id">
                      <a @click="$view('account', item?.accounts[0]._id)">
                        <UserAvatar
                          :size="30"
                          :square="false"
                          :responsive="false"
                          :user="item?.accounts[0]"
                          color="primary"
                          class="ma-1"
                        />
                        <span class="px-2">{{ item?.accounts[0]?.name }}</span>
                      </a>
                    </template>
                    <template v-else>
                      <UserAvatar
                        :key="index"
                        :size="30"
                        :square="false"
                        :responsive="false"
                        :user="{ name: BOOKING_SOURCE.MANUAL }"
                        :color="COLORS.GREY"
                        class="ma-1"
                      />
                      <span class="px-2">{{ BOOKING_SOURCE.MANUAL | capitalize }}</span>
                    </template>
                  </div>
                  <div v-else class="align-center d-flex justify-left">No profile</div>
                </template>

                <component
                  :is="header.component.name || header.component"
                  v-else
                  :value="getter(item, header)"
                  :item="item"
                  :header="header"
                  :options="options"
                  v-bind="header.component.props"
                  @callAction="callAction"
                ></component>
              </template>

              <template v-else-if="header.value === 'data-table-select'">
                <v-simple-checkbox :ripple="false" color="primary" :value="isSelected" @input="select($event)" />
              </template>

              <template v-else-if="header.tooltip">
                <vx-tooltip :value="getter(item, header)">{{ getter(item, header) }}</vx-tooltip>
              </template>

              <template v-else>
                {{ getter(item, header) }}
              </template>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="dataTable.headers.length" class="pa-5">
            <div class="float-right" :style="{ width: breakpoint.mdAndDown ? '41%' : '43%' }">
              <Profiles :profiles="item.accounts" />
            </div>
          </td>
        </template>
      </v-data-table>
      <MergeRecord
        v-model="dialog.merge"
        :selected-row="selectedRow"
        :options="mergeOptions"
        @resolve="resetAndGetData()"
        @reject="dialog.merge = false"
      ></MergeRecord>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import Filters from './filters';
import { UtilityService, UserService } from '@tutti/services';
import { BOOKING_SOURCE, COLORS } from '@tutti/constants';

import MergeRecord from '@admin/dialogs/mergeRecord.vue';

export default {
  name: 'DataTable',
  components: {
    MergeRecord,
    Filters,
    Profiles: () => import('./components/Profiles.vue'),
    UserAvatar: () => import('./components/UserAvatar.vue'),
  },
  props: {
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      created: false,
      action: null,
      actionsLength: !!this.options.actions?.length,
      dataTable: {
        key: '_id',
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        items: [],
        headers: [],
        value: [],
        itemsLength: 0,
        loading: true,
        footerProps: {
          showCurrentPage: true,
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 15, 25, 50, 100],
        },
      },
      userNames: [],
      expanded: [],
      BOOKING_SOURCE,
      COLORS,
      dialog: {
        merge: false,
      },
      selectedRow: {},
      mergeOptions: {},
    };
  },
  async created() {
    this.options.filter = this.options.filter || {};
    this.options.search = this.options.search || {};
    this.options.filters = this.options.filters || [];
    this.options.route = this.options.route || this.$route.meta.name;
    this.options.collection = this.options.collection || this.$route.meta.name;

    if (this.options.showHeader?.created || this.options.showHeader?.updated || this.options.showHeader?.publishedBy) {
      await this.getUserNames();
    }

    this.setHeaders();
    this.setOptions();

    this.getData();
    this.created = true;
  },
  methods: {
    debounce: _.debounce(async function () {
      await this.resetAndGetData();
    }, 500),

    async duplicate(data) {
      const confirm = await this.$confirm({
        title: UtilityService.generateMessage(this.options.route, 'duplicate'),
      });

      if (confirm) {
        const service = await import(`@tutti/services/${this.options.collection}.service`);

        const response = await service.default.adminDuplicate(data._id);
        if (response) {
          if (response.data._id) {
            this.$edit(this.options.route, response.data._id);
          } else {
            this.resetAndGetData();
          }
        }
      }
    },

    async deleteVenue(data) {
      const confirm = await this.$confirm({
        title: UtilityService.generateMessage(this.options.route, 'delete'),
      });

      if (confirm) {
        const service = await import(`@tutti/services/${this.options.collection}.service`);
        const response = await service.default.delete(data._id);
        if (response) {
          this.resetAndGetData();
        }
      }
    },

    onMergeRecords(data) {
      if (data) {
        this.dialog.merge = true;
        this.selectedRow = data;
        this.mergeOptions = _.cloneDeep(this.options);
      }
    },

    getter(item, header) {
      if (header.getter) {
        return header.getter(item) || 'N/A';
      }

      const value = this.$get(item, header.value);

      if (header.capitalize) {
        return _.capitalize(value);
      }

      if (value === undefined) {
        return 'N/A';
      }

      return value;
    },

    resetFilters() {
      this.options.filters.forEach(filter => {
        this.dataTable.options[filter.key] = [];
      });

      this.getData();
    },

    resetOptions() {
      this.dataTable.items = [];
      this.dataTable.options.page = 1;
      this.dialog.merge = false;
    },

    setOptions() {
      const query = _.cloneDeep(this.$route.query);

      Object.assign(this.options.search, query.search);
      Object.assign(this.options.filter, query.filter);

      if (query.page) {
        this.dataTable.options.page = +query.page;
      }

      if (query.limit) {
        this.dataTable.options.itemsPerPage = +query.limit;
      }

      if (query.sortBy && query.sortBy.length) {
        this.dataTable.options.sortBy = query.sortBy;
      }

      if (query.sortDesc && query.sortDesc.length) {
        this.dataTable.options.sortDesc = [query.sortDesc[0] === 'true'];
      }
    },

    getActionColumnHeight() {
      let width = 100;

      ['hideView', 'hideEdit'].forEach(key => {
        if (this.options.action?.[key]) {
          width -= 40;
        }
      });

      ['showDuplicate', 'showHistory', 'showChildList', 'showPreview', 'showEditAll', 'showDeleteVenue'].forEach(
        key => {
          if (this.options.action?.[key]) {
            width += 40;
          }
        }
      );

      const length = this.options.action?.actions?.length;
      if (length) {
        width += length * 40;
      }

      return `${width}px`;
    },

    setHeaders() {
      if (!this.options.hideAction) {
        this.dataTable.headers.push({
          text: 'Action',
          value: 'actions',
          component: {
            name: 'dtActions',
            props: [
              {
                duplicate: this.duplicate,
                options: this.options,
              },
              {
                deleteVenue: this.deleteVenue,
                options: this.options,
              },
              {
                mergeRecords: this.onMergeRecords,
                options: this.options,
              },
            ],
          },
          width: this.getActionColumnHeight(),
          sortable: false,
        });
      }

      this.dataTable.headers.push(...this.options.headers);

      if (this.options.showHeader?.publishedBy) {
        this.dataTable.headers.push({
          text: this.options.header?.publishedBy || 'Published by',
          value: 'publishedBy',
          width: '250px',
          filter: {
            type: 'autocomplete',
            items: this.userNames,
            itemText: 'displayName',
            itemValue: '_id',
          },
          component: { name: 'dtView', props: { name: 'user' } },
        });
      }

      if (this.options.showHeader?.created) {
        this.dataTable.headers.push({
          text: this.options.header?.created || 'Created by',
          value: 'createdBy',
          width: '250px',
          filter: {
            type: 'autocomplete',
            items: this.userNames,
            itemText: 'displayName',
            itemValue: '_id',
          },
          component: { name: 'dtView', props: { name: 'user' } },
        });
      }

      if (this.options.showHeader?.updated) {
        this.dataTable.headers.push({
          text: this.options.header?.updated || 'Updated by',
          value: 'updatedBy',
          width: '250px',
          filter: {
            type: 'autocomplete',
            items: this.userNames,
            itemText: 'displayName',
            itemValue: '_id',
          },
          component: { name: 'dtView', props: { name: 'user' } },
        });
      }

      if (this.options.showHeader?.publishedAt) {
        this.dataTable.headers.push({
          text: this.options.header?.publishedAt || 'Published at',
          value: 'publishedAt',
          width: '150px',
          component: 'dtDate',
        });
      }

      if (!this.options.hideHeader?.created) {
        this.dataTable.headers.push({
          text: this.options.header?.created || 'Created at',
          value: 'createdAt',
          width: '150px',
          component: 'dtDate',
        });
      }

      if (!this.options.hideHeader?.updated) {
        this.dataTable.headers.push({
          text: this.options.header?.updated || 'Updated at',
          value: 'updatedAt',
          width: '150px',
          component: 'dtDate',
        });
      }

      this.dataTable.headers.forEach(header => {
        header.width = header.width || '200px';
      });
    },

    resetAndGetData() {
      this.resetOptions();
      this.getData();
    },

    getQuery() {
      return {
        search: _.omitBy(this.options.search, x => UtilityService.isEmpty(x)),
        filter: _.omitBy(this.options.filter, x => UtilityService.isEmpty(x)),
        page: this.dataTable.options.page,
        limit: this.dataTable.options.itemsPerPage,
        sortBy: this.dataTable.options.sortBy,
        sortDesc: this.dataTable.options.sortDesc,
      };
    },

    createFilter() {
      const filter = this.getQuery();

      if (this.$route.meta.paramsKey && this.$route.params.id) {
        filter.filter[this.$route.meta.paramsKey] = this.$route.params.id;
      }

      if (filter.sortBy && filter.sortDesc) {
        const [sort] = filter.sortBy;
        const [desc] = filter.sortDesc;

        if (sort) {
          filter.sort = {
            [sort]: desc ? -1 : 1,
          };
        }
      }

      delete filter.sortBy;
      delete filter.sortDesc;

      return filter;
    },

    async getData() {
      this.dataTable.loading = true;
      const filter = this.createFilter();
      delete filter.sortBy;
      delete filter.sortDesc;
      this.$replaceState({ ...this.$route, query: this.getQuery() });

      const service = await import(`@tutti/services/${this.options.collection}.service`);

      const response = await service.default.getAll(filter);
      if (response) {
        const items = response.data.data || [];
        this.dataTable.itemsLength = response.data.count || 0;

        this.dataTable.items = items;
      }
      this.dataTable.loading = false;
    },

    async exportAll() {
      this.dataTable.loading = true;
      const filter = this.createFilter();

      const service = await import(`@tutti/services/${this.options.collection}.service`);

      const response = await service.default.getAll({
        ...filter,
        export: true,
      });
      if (response) {
        // exported successfully
      }
      this.dataTable.loading = false;
    },

    async addNew() {
      if (typeof this.options.addNew === 'function') {
        this.options.addNew();
      } else {
        this.$add(this.options.route);
      }
    },

    async onChangeAction(action) {
      const length = this.dataTable.value.length;

      if (action && length) {
        const key = action.message || Object.keys(action.body)[0];

        const res = await this.$confirm({
          title: UtilityService.generateMessage(this.options.route, key, action.body[key], length),
        });

        if (res) {
          const items = this.dataTable.value.map(item => ({
            _id: item._id,
            body: action.body,
          }));

          this.callAction(items);
        }
      }
    },

    async getUserNames() {
      const response = await UserService.getNames({ isAdmin: true });
      if (response) {
        this.userNames = response.data;
      }
    },

    async sync() {
      const service = await import(`@tutti/services/${this.options.collection}.service`);
      await service.default.sync(this.$route.params.id);
    },

    async callAction(items) {
      const service = await import(`@tutti/services/${this.options.collection}.service`);

      const response = await service.default.action({ items });

      if (response) {
        this.getData();
        this.dataTable.value = [];
        this.action = null;
      }
    },

    closeExpanded(item) {
      const index = this.expanded.indexOf(item);
      if (index > -1) {
        this.expanded.splice(index, 1);
      }
    },
  },
};
</script>
